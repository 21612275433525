import React, { useState } from "react";
import Header from "./views/Header";
import Home from "./views/Home";

import "./App.css";

function App() {
  return (
    <>
      <Header />
      <Home />
    </>
  );
}

export default App;
