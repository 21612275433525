import React from "react";
import LOGO from "../assets/images/ai-logo.png";

const Header = () => {
  return (
    <header className="bg-gray-800 text-white">
      <div className="container mx-auto flex justify-between items-center py-4 px-4">
        {/* Logo */}
        <div className="text-2xl font-bold">
          {/* <a href="#"> */}
          <img src={LOGO} height={300} width={300} />
          {/* </a> */}
        </div>

        {/* Navigation */}
        {/* <nav className="hidden md:flex space-x-6 text-xl">
          <a href="#" className="hover:text-gray-300">
            InBound
          </a>
          <a href="#" className="hover:text-gray-300">
            OutBound
          </a>
        </nav> */}

        {/* Mobile Menu Button */}
        {/* <div className="md:hidden">
          <button className="focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div> */}
      </div>
    </header>
  );
};

export default Header;
