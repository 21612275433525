import OutBound from "./outBound";

const Home = () => {
  const phoneNumber = "+1 727-493-2240";

  const handleCopyNumber = () => {
    navigator.clipboard
      .writeText(phoneNumber)
      .then(() => alert("Phone number copied to clipboard!"))
      .catch((err) => alert("Failed to copy phone number: ", err));
  };
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 home-sec">
      <img
        alt=""
        // src="https://media.istockphoto.com/id/1866799975/photo/businessman-using-mobile-and-touch-microphone-button-on-virtual-screen-blog-blog-speak-talk.jpg?s=612x612&w=0&k=20&c=VULw_KvkNBjNu1cVHsHBpI_lA4vgdmnnCqgQuOJZqyk="
        src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
        className="absolute inset-0 -z-10 h-full w-full"
      />
      <div
        aria-hidden="true"
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#610c19] to-[#c4b4b4] opacity-20"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#610c19] to-[#c4b4b4] opacity-20"
        />
      </div>
      <div className="mx-auto px-6 lg:px-8">
        <div className="mx-auto text-center m-8 lg:mx-0 in-bound-div">
          <a
            href={`tel:${phoneNumber}`}
            className="inline-flex items-center px-4 py-2 mr-2 text-white bg-[#981629] rounded-lg hover:bg-[#dd606f]"
          >
            Talk to Speak Smart AI &nbsp; {phoneNumber}
          </a>
          <button
            onClick={handleCopyNumber}
            className="inline-flex items-center px-4 py-2 text-white bg-gray-600 rounded-lg hover:bg-gray-700 max-sm:my-2"
          >
            {/* Copy Number */}
            <i className="fa-solid fa-copy" />
          </button>
        </div>
        <div className="mx-auto lg:mx-0 text-center">
          <h6 className="text-4xl font-bold tracking-tight text-white sm:text-2xl">
            Automate calls and tasks using AI agents built within minutes
          </h6>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Maximize the potential to develop a truly conversational and
            human-like voice experience.
          </p>
        </div>
      </div>
      <OutBound />
    </div>
  );
};

export default Home;
