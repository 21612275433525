import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import axios from "axios";

const countryOptions = [
  { value: "+1", label: "United States (+1)" },
  { value: "+91", label: "India (+91)" },
  { value: "+44", label: "United Kingdom (+44)" },
  // Add more country options as needed
];

const validationSchema = Yup.object({
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .matches(/^\d{7,14}$/, "Invalid Phone Number format"),
  countryCode: Yup.string().required("Country code is required"),
});

const OutBound = () => {
  const handleFormSubmit = async (values, { setStatus }) => {
    const data = {
      agent_id: process.env.REACT_APP_AGENT_ID,
      recipient_phone_number: values.countryCode + values.phoneNumber,
    };

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      console.log("API URL:", apiUrl);

      const response = await axios.post(`${apiUrl}/call`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setStatus({ type: "success", message: "Call placed successfully" });
    } catch (error) {
      console.error("Error:", error);
      setStatus({
        type: "error",
        message: error.response
          ? `Error (${error.response.status}): ${
              error.response.data.message || error.response.statusText
            }`
          : "Error: Something went wrong",
      });
    }
  };

  return (
    <div className="p-4 max-w-md mx-auto">
      <h2 className="text-xl font-bold mb-4 text-white text-center">
        Make AI Call
      </h2>
      <Formik
        initialValues={{ phoneNumber: "", countryCode: "" }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ status, setFieldValue, values }) => (
          <Form className="space-y-4">
            <div>
              <label
                htmlFor="phoneNumber"
                className="block text-md font-medium text-white"
              >
                Phone Number:
              </label>
              <div className="flex align-items-baseline space-x-2 max-sm:flex-col">
                <Select
                  id="countryCode"
                  name="countryCode"
                  options={countryOptions}
                  onChange={(option) =>
                    setFieldValue("countryCode", option ? option.value : "")
                  }
                  value={countryOptions.find(
                    (option) => option.value === values.countryCode
                  )}
                  className="flex-shrink-0 w-32 max-sm:w-full max-sm:mb-2"
                />
                <Field
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  className="ml-2 flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm max-sm:w-full max-sm:!mx-0"
                />
              </div>
              <ErrorMessage
                name="phoneNumber"
                component="div"
                className="text-red-600 text-sm"
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-black bg-[#c4b4b4] hover:bg-[#c4b4b4] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#c4b4b4]"
            >
              Make AI Call
            </button>
            {status && (
              <div
                className={`mt-4 p-2 rounded-md ${
                  status.type === "success"
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800"
                }`}
              >
                {status.message}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OutBound;
